import  React, { Fragment, useEffect,useRef } from 'react';
import axios from "axios";
import { useState } from "react";

import { Link, useNavigate, useParams } from 'react-router-dom';



import SignaturePad from 'signature_pad';
import { Html5QrcodeScanner } from 'html5-qrcode';



export default function Livraison(){
  
    const { id_state } = useParams();
const { titre } = useParams();
    const [scanResult, setScanResult] = useState(null);
    const [email_agent, setAuth] = useState('');
const [agences, setAgences] = useState([]);
const [filter, setFilter] = useState("");
const navigate = useNavigate();
const [successMessage, setSuccessMessage] = useState("");
  const REDIRECT_DELAY = 2000; 
  const [agences30, setAgences30] = useState([]);
  
  const userRole = localStorage.getItem('role');
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const toggleMenu = () => {
	  setMenuOpen(!menuOpen);
	  setMenuActive(!menuOpen);
	};
	const closeMenu = () => {
	  setMenuOpen(!menuOpen);
	  setMenuActive(!menuOpen);	// Code pour fermer le menu
	};
  
  const closeQr = () => {
	 document.getElementById('oki').click();
	};

  const [isLoading, setIsLoading] = useState(false); // Add a new state variable for loading status



  const signaturePadRef = useRef(null);

  const [data1, setData1] = useState(null);
  useEffect(() => {

    const success = async (result) => {
      setScanResult(result);
     
      const sendScanResult = async (result) => {
        setIsLoading(true);
        const url = `https://lapostecolis.laposte-ga.com/qrapp/livraison1/`;
        const data = { scanResult: result };
  
        try {
          const response = await axios.post(url, data);
          console.log(response.data);
          setData1(response.data);
          setIsLoading(false);
          document.getElementById('sign').style.display='block';
         
        } catch (error) {
          console.error(error);
        }
      };
  
      await sendScanResult(result);
  
      setSuccessMessage("Code QR scanné avec succès !");
  
      setTimeout(() => {
        document.getElementById('noti').innerHTML = '';
        setScanResult(null);
      }, REDIRECT_DELAY);
    };
  
    const error = (err) => {
      console.warn(err);
    };
  
    const scanner = new Html5QrcodeScanner('reader', {
      qrbox: {
        width: 250,
        height: 250,
      },
      fps: 5,
    });
  
    scanner.render(success, error);
   
  }, []);
  



  
  
 
  



 


useEffect(() => {

 
  
 




            
  const loadScripts = () => {
      // Remove existing script elements added by the component
      const scriptElements = Array.from(document.getElementsByTagName('script'));
      const componentScriptElements = scriptElements.filter((script) => script.getAttribute('data-component-script'));

      componentScriptElements.forEach((script) => {
        document.body.removeChild(script);
      });


  
    const script1 = document.createElement('script');
    script1.src = 'https://cdnjs.cloudflare.com/ajax/libs/signature_pad/1.5.3/signature_pad.min.js';
    script1.async = true;



    const script3 = document.createElement('script');
    script3.src = './assets/assets/js/jquery-3.6.0.min.js';
    script3.async = true;

   
    const script5 = document.createElement('script');
    script5.src = './assets/assets/js/bootstrap.bundle.min.js';
    script5.async = true;


    const script6 = document.createElement('script');
    script6.src = './assets/assets/plugins/swiper/js/swiper.min.js';
    script6.async = true;

    const script8 = document.createElement('script');
    script8.src = './assets/assets/js/script.js';
    script8.async = true;


    document.body.appendChild(script1);

    document.body.appendChild(script3);

    document.body.appendChild(script5);
   
    document.body.appendChild(script6);
   
    document.body.appendChild(script8);
   
  };
    loadScripts();


    return () => {
      const scriptElements = Array.from(document.getElementsByTagName('script'));
      const componentScriptElements = scriptElements.filter((script) => script.getAttribute('data-component-script'));

      componentScriptElements.forEach((script) => {
        document.body.removeChild(script);
      });
    };



    
  

}, []);


useEffect(() => {
  const canvas = document.getElementById('signaturePad');
  if (canvas) {
    signaturePadRef.current = new SignaturePad(canvas);
  }

  // Cleanup function
  return () => {
    if (signaturePadRef.current) {
      signaturePadRef.current.off(); // Remove event listeners
    }
  };
}, []);

const [idColis, setIdColis] = useState(null);
const [lien, liens] = useState(null);

useEffect(() => {
  // Check if data1 is not null before accessing its properties
  if (data1 && data1.length > 0) {
    setIdColis(data1[0].id_colis);
    if(data1[1].lien){
        liens(data1[1].lien);
        }else{
            liens(data1[0].id_sac);
        }
  }
}, [data1]);

const saveSignature = () => {
   document.getElementById('notif').innerHTML='Signature validée avec succès !';
  const signatureData = signaturePadRef.current.toDataURL(); // Obtenez les données de la signature sous forme d'URL
  const formData = new FormData();
  formData.append('id_colis', lien);
  formData.append('signature', signatureData);

  fetch('https://lapostecolis.laposte-ga.com/qrapp/sign1/', {
    method: 'POST',
    body: formData,
  })
    .then(response => response.json())
    .then(data => {
      console.log(data);// Faites quelque chose avec la réponse du serveur
     
    })
    .catch(error => {
      // Gérez les erreurs de la requête
    });
};

const clearSignature = () => {
  if (signaturePadRef.current) {
    signaturePadRef.current.clear();
  }
};
const email = localStorage.getItem('id_admin');
  if (email === null) {
    navigate('/');
  }
const logOut = () => {
  localStorage.removeItem('id_admin');
  localStorage.clear();
  navigate('/');
};

    return(
    <Fragment>
		
								
									

        <div className="main-wrapper">

<div className="home">
<div className="navbar two-action no-hairline">
<div className="navbar-inner d-flex align-items-center">

<div style={{textAlign:'center'}} className="sliding custom-title">Scan Livraison colis</div>

</div>
</div>
</div>





<div className="page-content">
    
    
    

  







<div className="row gy-5 g-xl-10">



















<div className="col-xl-12 mb-5 mb-xl-10">
									
                                    <div className="card card-flush h-xl-100">
<div className="card-header pt-7">
											
<h3 className="card-title align-items-start flex-column">
													<span className="card-label fw-bold text-gray-800">Scanner Code QR {titre}</span>
													<span className="text-gray-400 mt-1 fw-semibold fs-6"></span>
												</h3>						
											
											
												
											
											</div>


                                            <div className="card-body bg-light">
											
										
												
                                            <div className="tab-content">

<div  id='example1_wrapper120r'>
<div id='noti'>
{successMessage && (
                <div className="alert alert-success" role="alert">
                  {successMessage}
                </div>

                
              )}
</div>


        <div id="reader"></div>









               </div>
               {isLoading ? (
    <div align='center' style={{textAlign:'center'}}>  <span style={{fontSize:'30px'}} className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span></div>
    ) : (
       <></>  
      )}

<div id='sign' style={{display:'none'}}>
               {data1 &&(
                <>
               {data1 && data1.length>=3 ?(
               <div style={{overflow:'auto',height:'300px',marginTop:'30px'}}>
               <table className='table'>
                
             {data1.map((data, index) => (
                     <div key={index}>
                     <tr>
                       {Object.entries(data).map(([key, value]) => (
                         <td key={key}>
                           {key!=='lien' && key!='0' && key!='id_sac' && key!='nom_colis'  ?(
                            key==='3'?(
                             <>  S-00{value}</>
                            ):key==='id_colis'?(
                             <> P-00{value}</>
                            ):(
                             <>{value}</>
                            )
                            
                         
                           ):(
                             <></>
                           )}
                         </td>
                       ))}
                       </tr>
                      
                     </div>
                   ))}
                  
             </table>
               </div>
  
) : (
  <div style={{overflowX:'auto',overflowY:'auto',maxHeight:'350px'}}>
  <table style={{ width: '100%', textAlign: 'center', marginTop: '10px' }} className='table table-hover hover'>
    <thead>
      <tr>
        <th>Matri.</th>
        <th>Nature</th>
        <th>Destination</th>
        <th>N. Exp</th>
        <th>N. Récep</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>P-00{data1[0].id_colis}</td>
        <td>{data1[0].nom_colis}</td>
        <td>{data1[0].destination_colis}</td>
        <td>{data1[0].telephone_exp}</td>
        <td>{data1[0].telephone_rec}</td>
      </tr>
    </tbody>
  </table>
</div>
)}</>
               )}


                
            <div align="center">
   


            <canvas
        ref={signaturePadRef}
        id="signaturePad"
        style={{ height: '150px', width: '300px', background: 'orange', borderRadius: '5px' }}
      ></canvas>
      <br />
      <input type="text" style={{display:'none'}} value={lien} name="id_colis" onChange={e => setIdColis(e.target.value)} />
      <button
        onClick={saveSignature}
        className="btn"
        style={{ borderColor: 'black', marginTop: '10px' }}
      >
        <i className="fa fa-save"></i> Sauvegarder
      </button>
      <button
        onClick={clearSignature}
        className="btn"
        style={{ borderColor: 'black', marginLeft: '3px', marginTop: '10px' }}
      >
        <i className="fa fa-trash"></i> Effacer
      </button>
    
      </div> </div>
      <div align="center" id='notif' className='text-success' style={{fontWeight:'bold'}}></div>
 

        
                        
                        
                        
                        </div>
							
						</div>

                        </div>
							
						</div>











                        </div>







                        <div>
                          

                        








    
      
    
   
   
   
   </div>
   
   
   
   
 
 </div>
 
 
 
 
 
 </div>
 
 
 
 
  <nav className="mobile-bottom-nav">
     <div className="mobile-bottom-nav__item mobile-bottom-nav__item">
         <Link to='/Sacs'>
         <div className="mobile-bottom-nav__item-content">
         <i className="fa fa-qrcode"></i>
             Gén. Qr sac
         </div>
         </Link>
     </div>
     <div className="mobile-bottom-nav__item">	
     <Link to='/Cnt'>
         <div className="mobile-bottom-nav__item-content">
         <i className="fa fa-qrcode"></i>
             CNT
         </div>
         </Link>
     </div>
     <div style={{color:'#22427C'}} className="mobile-bottom-nav__item">
     <Link to='/Arrive'>
         <div className="mobile-bottom-nav__item-content">
         <i className="fa fa-qrcode"></i>
             Arrivée dest.
         </div>	
         </Link>
     </div>
     
         <div style={{color:'#22427C'}} className="mobile-bottom-nav__item">
         <Link to='/Livraison' style={{color:'#22427C'}}>
         <div className="mobile-bottom-nav__item-content">
         <i className="fa fa-qrcode"></i>
             Livraison
         </div>	
         </Link>
     </div>
     
     <div className="mobile-bottom-nav__item">
     <Link to='/' onClick={logOut} >
         <div className="mobile-bottom-nav__item-content">
         <i className="fa fa-power-off"></i>
             Decon.
         </div>	
         </Link>
     </div>
 </nav> 





                       
</Fragment>

    )
}
