import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Cnt from './Pages/Cnt';
import Arrive from './Pages/Arrive';
import Livraison from './Pages/Livraison';
import Sacs from './Pages/Sacs';
import Login from './Pages/Login';


function App() {
  return (
    <div className="App">
        <Router>
        <Routes>
          <Route path="/Cnt" element={<Cnt />} />
          <Route path="/Arrive" element={<Arrive />} />
          <Route path="/Livraison" element={<Livraison />} />
          <Route path="/Sacs" element={<Sacs />} />
          <Route path="/" element={<Login />} />

        </Routes>
      </Router>
 
    </div>
  );
}



export default App;
